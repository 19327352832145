import React, { useState, useMemo, useEffect, useRef } from "react"
import {
	useReactTable,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	setGlobalFilter, // Added for global filtering
	getGroupedRowModel,
	getSortedRowModel,
	getExpandedRowModel,
	getPaginationRowModel
} from "@tanstack/react-table"
import {
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	Box,
	Flex,
	Select,
	Input, // Imported Input for search
	Text,
	IconButton,
	SkeletonCircle,
	SkeletonText,
	Skeleton,
	Alert,
	Button,
	Menu,
	MenuButton,
	MenuList,
	MenuItemOption,
	MenuOptionGroup,
	HStack,
	ButtonGroup,
	InputGroup,
	InputLeftAddon,
	Tooltip
} from "@chakra-ui/react"
import {
	ChevronLeftIcon,
	ChevronRightIcon,
	ArrowLeftIcon,
	ArrowRightIcon,
	ChevronDownIcon,
	ChevronUpIcon
} from "@chakra-ui/icons"
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons"

import { HiOutlineRectangleGroup } from "react-icons/hi2"
import { FaSearch } from "react-icons/fa"
import { IoMdSearch } from "react-icons/io"

import { LuUsers } from "react-icons/lu"

import DecoratorTriangleButtons from "../../GUI/DecoratorTriangleButtons"
import DecoratorTriangleButtonResizableFlipped from "../../GUI/DecoratorTriangleButtonResizableFlipped"
import DecoratorTriangleButtonResizable from "../../GUI/DecoratorTriangleButtonResizable"
import { useDevices } from "../../../api/fetchHooks/useDevices"
import EditableCell from "./EditableCell"
import DecoratorTriangleInputSelectResizable from "../../GUI/DecoratorTriangleInputSelectResizable"
import DotSpinner from "../../GUI/DotSpinner"
import { useQueryClient } from "@tanstack/react-query"
import {
	LiaSortAmountDownAltSolid,
	LiaSortAmountUpAltSolid
} from "react-icons/lia"
import { useTranslate } from "react-polyglot"
import axios from "axios"
import { toast } from "react-toastify"
import { useAuth } from "../../../contexts/AuthContext"

const createColumns = (t) => [
	{
		accessorKey: "serialNumber",
		header: () =>
			t("DevicesTable.deviceTableColumnTitleSerialnumber", {
				_: "Seriennummer"
			}),
		cell: (info) => info.getValue() || "N/A",
		size: 190,
		minSize: 190
	},
	{
		accessorKey: "deviceDisplayName",
		header: () =>
			t("DevicesTable.deviceTableColumnTitleDeviceName", {
				_: "Gerätename"
			}),
		cell: (props) => <EditableCell {...props} />,
		size: 220,
		minSize: 180,
		meta: {
			align: "left"
		}
	},
	{
		accessorFn: (row) => {
			const version = row.firmwareVersion || "N/A"
			const number = row.firmwareNumber || "N/A"
			return `${version} (${number})`
		},
		id: "firmware",
		header: () =>
			t("DevicesTable.deviceTableColumnTitleFirmware", {
				_: "Firmware"
			}),
		cell: (info) => info.getValue(),
		size: 140,
		minSize: 140,
		enableGrouping: true
	},
	{
		accessorFn: (row) => row.hardwarePrintNumberAndVersion || "N/A",
		id: "hardware",
		header: () =>
			t("DevicesTable.deviceTableColumnTitlePrint", {
				_: "Hardware"
			}),
		cell: (info) => info.getValue(),
		size: 150,
		minSize: 140,
		enableGrouping: true
	},
	{
		accessorFn: (row) => ({
			assemblyVariantAndVersion: row.assemblyVariantAndVersion
		}),
		id: "variant",
		header: () =>
			t("DevicesTable.deviceTableColumnTitleVariant", {
				_: "Variante"
			}),
		cell: (info) => {
			const { assemblyVariantAndVersion } = info.getValue()
			return `${assemblyVariantAndVersion || "N/A"}`
		},
		size: 150,
		minSize: 140
	},
	{
		accessorKey: "latestConfigDate",
		header: () =>
			t("DevicesTable.deviceTableColumnTitleLatestConfigDate", {
				_: "Zuletzt konfiguriert"
			}),
		cell: (info) =>
			info.getValue() ? new Date(info.getValue()).toLocaleString() : "N/A",
		size: 240,
		minSize: 140,
		sortType: "datetime", // custom sort type for date object
		enableGlobalFilter: false
	}
]

// Define a global filter function
const globalFilterFunction = (row, columnIds, filterValue) => {
	return columnIds.some((columnId) => {
		const value = row.getValue(columnId)
		return String(value)
			.toLowerCase()
			.includes(String(filterValue).toLowerCase())
	})
}

//# DEVICES TABLE
export default function DevicesTable({ initialData }) {
	const t = useTranslate()
	const queryClient = useQueryClient()
	const { userClaims } = useAuth()
	const [tenantId, setTenantId] = useState(userClaims?.tenantId || "")

	const [isUserClaimsLoaded, setIsUserClaimsLoaded] = useState(false)

	const [tenants, setTenants] = useState([])

	useEffect(() => {
		if (userClaims) {
			setTenantId(userClaims.tenantId || "")
			setIsUserClaimsLoaded(true)
		}
	}, [userClaims])

	const isEvisMasterTenant =
		userClaims?.role === "evisAdmin" || userClaims?.tenantId === "0000" //info: on first load tenantId is not available yet , but role is already available

	const { data: devices, isLoading, isError } = useDevices(tenantId)

	useEffect(() => {
		const fetchTenants = async () => {
			try {
				const response = await axios.get("/api/getTenants")
				setTenants(response.data)
			} catch (error) {
				console.error("Error fetching tenants:", error)
				toast.error("Failed to fetch tenants")
			}
		}
		fetchTenants()
	}, [])

	const handleTenantChange = (e) => {
		setTenantId(e.target.value)
	}

	//# DEVICES TABLE CONFIG - PAGINATION, GROUPING, SORTING, SEARCH
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 10
	})

	const [grouping, setGrouping] = useState([])

	// State for global search
	const [globalFilter, setGlobalFilter] = useState("")

	const memoizedDevices = useMemo(() => devices || [], [devices])
	const columns = useMemo(() => createColumns(t), [t])
	const skipPageResetRef = useRef(false)

	// Custom sort types correctly using a function
	const sortTypes = useMemo(
		() => ({
			datetime: (rowA, rowB, columnId) => {
				const a = new Date(rowA.getValue(columnId))
				const b = new Date(rowB.getValue(columnId))
				return a > b ? 1 : a < b ? -1 : 0
			}
		}),
		[]
	)

	const table = useReactTable({
		columns,
		data: memoizedDevices,
		getRowId: (originalRow) => originalRow.uuid,
		initialState: {
			columnOrder: [
				"serialNumber",
				"deviceDisplayName",
				"firmware",
				"hardware",
				"variant",
				"latestConfigDate"
			],
			columnVisibility: {
				serialNumber: true,
				deviceDisplayName: true,
				firmware: true,
				hardware: true,
				variant: true,
				latestConfigDate: true
			},

			expanded: true,
			sorting: [
				{
					id: "serialNumber",
					asc: true
				}
			],
			pagination: {
				pageIndex: pagination.pageIndex,
				pageSize: pagination.pageSize
			},
			defaultColumn: {
				minSize: 50,
				maxSize: 500
			},
			enableColumnResizing: true,
			columnResizeMode: "onChange",
			grouping: [],
			expanded: {},
			globalFilter: "" // Initialize globalFilter
		},
		state: {
			pagination,
			grouping,
			globalFilter, // Add globalFilter to table state
			autoResetPageIndex: !skipPageResetRef.current
		},
		onPaginationChange: setPagination,
		onGroupingChange: setGrouping,
		onGlobalFilterChange: setGlobalFilter, // Handle global filter changes
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getGroupedRowModel: getGroupedRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		// globalFilterFn: globalFilterFunction, // Use the custom filter function
		globalFilterFn: "includesString", //!! Use this tanstack filter function
		meta: {
			updateData: (serialNumber, columnId, value) => {
				// console.log(
				// 	`Updated ${columnId} for device ${serialNumber} to ${value}`
				// )
			}
		},
		autoResetPageIndex: false,
		autoResetExpanded: false,
		autoResetSorting: false,
		autoResetSelectedRows: false,
		autoResetFilters: false,
		sortTypes // Add custom sort types here
	})

	useEffect(() => {
		if (skipPageResetRef.current) {
			skipPageResetRef.current = false
		}
	}, [memoizedDevices])

	const handleGroupingChange = (selectedOptions) => {
		setGrouping(selectedOptions)
	}

	// Update the handler for global filter change
	const handleGlobalFilterChange = (e) => {
		setGlobalFilter(String(e.target.value))
	}

	if (!isUserClaimsLoaded) {
		return (
			<Flex
				mt={"15px"}
				gap={6}
				justifyContent="center"
				alignItems="center"
			>
				<DotSpinner />
				<Text>Loading</Text>
			</Flex>
		)
	}
	// if (!isEvisMasterTenant) {
	// 	return <DotSpinner />
	// }

	if (isLoading) {
		return (
			<Flex
				mt={"15px"}
				gap={6}
				justifyContent="center"
				alignItems="center"
			>
				<DotSpinner />
				<Text>Loading</Text>
			</Flex>
		)
	}

	if (isError) {
		return (
			<Alert status="error">
				{t("DevicesTable.deviceTableError", {
					_: "Fehler beim Laden der Geräte"
				})}
			</Alert>
		)
	}


	return (
		<Box
			mt={4}
			pb={24}
			ml={{ base: -4, md: -6 }}
			width="auto"
			maxW="max-content"
		>
			{/* //# BUTTONS TABLE */}

			<Menu closeOnSelect={false}>
				<ButtonGroup
					// variant="outline"
					spacing="8"
					ml={2}
				>
					{/* Tenant Selection */}
					{isEvisMasterTenant && (
						<Flex
							mb={4}
							alignItems="center"
							ml={6}
						>
							<InputGroup>
								<Tooltip
									label={t("DevicesTable.deviceTableTenantSelectionTooltip", {
										_: "Distributor auswählen"
									})}
									hasArrow={true}
									bg="tooltip-bg"
									aria-label="tooltip showing current configuration format"
									placement="top-start"
									openDelay={300}
									closeDelay={200}
								>
									<InputLeftAddon
										background={"field-addon-bg"}
										height="24px"
										border={"none"}
										// mt={"1px"}
										// borderColor={
										// 	localStorage.getItem("chakra-ui-color-mode") === "light"
										// 		? "#cbd5e0"
										// 		: "#cbd5e0"
										// }
									>
										<LuUsers
											size={16}
											height="24px"
											fontWeight={"bold"}
										/>
									</InputLeftAddon>
								</Tooltip>
								<Select
									value={tenantId}
									onChange={handleTenantChange}
									// size="sm"
									mr={4}
									width="max-content"
									height="24px"
									border={"none"}
									cursor="pointer"
								>
									{tenants.map((tenant) => (
										<option
											key={tenant.tenantId}
											value={tenant.tenantId}
										>
											{tenant.tenantId} - {tenant.tenantName}
										</option>
									))}
								</Select>
								{/* <DecoratorTriangleInputSelectResizable
									backgroundColor="bg-default"
									decoratorTriangleHeight="26px"
									decoratorTriangleWidth="32px"
									decoratorTriangleMl="-46px"
								/> */}
								<DecoratorTriangleButtonResizableFlipped
									backgroundColor="bg-default	"
									borderColor="bg-default"
									decoratorTriangleHeight={"26px"}
									decoratorTriangleWidth={"30px"}
									decoratorTriangleMl={"-45px"}
								/>
							</InputGroup>
						</Flex>
					)}
					
					{/* //# Grouping */}
					<Flex
						mb={4}
						alignItems="center"
					>
						<Tooltip
							label={t("DevicesTable.deviceTableGroupingTooltip", {
								_: "Gruppieren"
							})}
							hasArrow={true}
							bg="tooltip-bg"
							aria-label="tooltip showing current configuration format"
							placement="top-start"
							openDelay={300}
							closeDelay={200}
						>
							<Box
								background={"field-addon-bg"}
								height="24px"
								mt={"1px"}
								px={3.5}
							>
								<HiOutlineRectangleGroup
									size={22}
									mr={2}
									color={"white"}
									opacity={0.85}
								/>
							</Box>
						</Tooltip>
						<MenuButton
							as={Button}
							height="24px"
							border={"none"}
						>
							{grouping.length > 0 ? (
								t("DevicesTable.deviceTableColumnTitleGroupedBy", {
									_: "Gruppiert nach"
								}) +
								" " +
								grouping.join(" & ")
							) : (
								<Flex alignItems="center">
									<HStack gap={2}>
										<Text>
											{t("DevicesTable.deviceTableGrouping", {
												_: "Gruppieren"
											})}
										</Text>
									</HStack>
								</Flex>
							)}
						</MenuButton>
						{/* </Tooltip> */}
						<DecoratorTriangleButtons
							backgroundColor="btn-primary-hover-bg"
							borderColor="btn-primary-border"
						/>
					</Flex>

					{/* //# Search */}
					<Flex
						mb={4}
						alignItems="center"
					>
						<InputGroup>
							<Tooltip
								label={t("DevicesTable.deviceTableSearchTooltip", {
									_: "Tabelle durchsuchen"
								})}
								hasArrow={true}
								bg="tooltip-bg"
								aria-label="tooltip showing current configuration format"
								placement="top-start"
								openDelay={300}
								closeDelay={200}
							>
								<InputLeftAddon
									background={"field-addon-bg"}
									height="24px"
									// mt={"1px"}
								>
									<IoMdSearch
										size={18}
										height="25px"
									/>
								</InputLeftAddon>
							</Tooltip>
							<Input
								placeholder={t("DevicesTable.deviceTableSearchPlaceholder", {
									_: "Suche..."
								})}
								sx={{
									"&::placeholder": {
										color: "field-placeholder-text"
									}
								}}
								value={globalFilter}
								onChange={handleGlobalFilterChange}
								maxW="300px"
								height="24px"
								border={"none"}
								width="176px"
							/>
							<DecoratorTriangleButtons
								backgroundColor="btn-primary-hover-bg"
								borderColor="btn-primary-border"
							/>
						</InputGroup>
					</Flex>
				</ButtonGroup>
				<MenuList minWidth="240px">
					<MenuOptionGroup
						type="checkbox"
						value={grouping}
						onChange={handleGroupingChange}
					>
						<MenuItemOption value="firmware">Firmware</MenuItemOption>
						<MenuItemOption value="hardware">Hardware</MenuItemOption>
					</MenuOptionGroup>
				</MenuList>
			</Menu>
			<Table
				variant="simple"
				// variant="striped"
				// colorScheme={"blackAlpha"}
				styles={{
					tr: {
						"&:nth-of-type(odd)": {
							backgroundColor: "rgba(0, 0, 0, 0.05)"
						}
					}
				}}
				size="md"
				style={{ tableLayout: "auto", width: "auto" }}
			>
				<Thead>
					{table.getHeaderGroups().map((headerGroup) => (
						<Tr key={headerGroup.id}>
							{headerGroup.headers.map((header) => (
								<Th
									key={header.id}
									subHeaders={header.subHeaders}
									textAlign={
										header.column.columnDef.meta?.align === "left"
											? "left"
											: "right"
									}
									// textAlign="right"
									style={{
										width: header.getSize(),
										minWidth: header.column.columnDef.minSize
									}}
								>
									<Flex
										alignItems="center"
										justifyContent={
											header.column.columnDef.meta?.align === "left"
												? "flex-start"
												: "flex-end"
										}
										pr={0}
										cursor={header.column.getCanSort() ? "pointer" : "default"}
										onClick={header.column.getToggleSortingHandler()}
									>
										{header.column.columnDef.meta?.align !== "left" && (
											<Box mr={2}>
												{{
													asc: <LiaSortAmountUpAltSolid size={18} />,
													desc: <LiaSortAmountDownAltSolid size={18} />
												}[header.column.getIsSorted()] ?? null}
											</Box>
										)}
										{flexRender(
											header.column.columnDef.header,
											header.getContext()
										)}
										{header.column.columnDef.meta?.align === "left" && (
											<Box ml={2}>
												{{
													asc: <LiaSortAmountUpAltSolid size={18} />,
													desc: <LiaSortAmountDownAltSolid size={18} />
												}[header.column.getIsSorted()] ?? null}
											</Box>
										)}
									</Flex>
									{header.column.getCanResize() && (
										<Box
											onMouseDown={header.getResizeHandler()}
											onTouchStart={header.getResizeHandler()}
											className={`resizer ${
												header.column.getIsResizing() ? "isResizing" : ""
											}`}
										/>
									)}
								</Th>
							))}
						</Tr>
					))}
				</Thead>
				<Tbody>
					{table.getRowModel().rows.map((row) => (
						<Tr
							key={row.id}
							data-row-index={row.index}
						>
							{row.getVisibleCells().map((cell) => {
								if (cell.getIsGrouped()) {
									return (
										<Td
											key={cell.id}
											colSpan={table.getAllLeafColumns().length}
										>
											<Flex alignItems="center">
												<IconButton
													// clipPath="polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)"
													borderRadius={"3px"}
													icon={
														row.getIsExpanded() ? (
															<TriangleUpIcon fontSize={"15px"} />
														) : (
															<TriangleDownIcon fontSize={"15px"} />
														)
													}
													onClick={row.getToggleExpandedHandler()}
													aria-label="Toggle group"
													size="sm"
													mr={2}
													bg={"bg-default"}
													border={"none"}
												/>
												{t(
													`DevicesTable.deviceTableColumnTitle${
														cell.column.id.charAt(0).toUpperCase() +
														cell.column.id.slice(1)
													}`,
													{ _: cell.column.id }
												)}
												: {cell.getValue()} ({row.subRows.length})
											</Flex>
										</Td>
									)
								}
								if (cell.getIsAggregated()) {
									return null
								}
								if (cell.getIsPlaceholder()) {
									return <Td key={cell.id}></Td>
								}
								return (
									<Td
										key={cell.id}
										textAlign="end"
										py={1.5}
										style={{
											width: cell.column.getSize(),
											minWidth: cell.column.columnDef.minSize
										}}
									>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</Td>
								)
							})}
						</Tr>
					))}
				</Tbody>
			</Table>
			{/* //# Pagination - Rows per page */}
			<Flex
				mt={8}
				height="26px"
				alignItems="center"
				justifyContent="space-between"
				fontSize="sm"
			>
				<Flex
					alignItems="center"
					ml={6}
				>
					<Text
						mr={3}
						whiteSpace="nowrap"
					>
						{t("DevicesTable.deviceTablePaginationRowsPerPage", {
							_: "Zeilen pro Seite"
						})}
					</Text>
					<Flex alignItems="center">
						<Select
							size="sm"
							height="26px"
							width="70px"
							value={pagination.pageSize}
							onChange={(e) => {
								setPagination((prev) => ({
									...prev,
									pageSize: Number(e.target.value)
								}))
							}}
						>
							{[5, 10, 20, 50, 100].map((pageSize) => (
								<option
									key={pageSize}
									value={pageSize}
								>
									{pageSize}
								</option>
							))}
						</Select>
						<DecoratorTriangleInputSelectResizable
							backgroundColor={"bg-default"}
							decoratorTriangleHeight={"26px"}
							decoratorTriangleWidth={"30px"}
							decoratorTriangleMl={"-30px"}
						/>
					</Flex>
				</Flex>
				{/* //# Pagination - Page index */}
				<Flex
					alignItems="center"
					mr={5}
				>
					<IconButton
						icon={<ArrowLeftIcon fontSize={"12px"} />}
						onClick={() => setPagination((prev) => ({ ...prev, pageIndex: 0 }))}
						isDisabled={!table.getCanPreviousPage()}
						mr={2}
						size="sm"
						height="26px"
						aria-label={"firstPage"}
						borderRadius={"3px"}
					/>
					<IconButton
						icon={<ChevronLeftIcon />}
						onClick={() =>
							setPagination((prev) => ({
								...prev,
								pageIndex: prev.pageIndex - 1
							}))
						}
						isDisabled={!table.getCanPreviousPage()}
						mr={2}
						size="sm"
						height="26px"
						aria-label={"previousPage"}
						borderRadius={"3px"}
					/>
					<Flex
						alignItems="center"
						mx={2}
					>
						<Input
							type="number"
							min={1}
							max={table.getPageCount()}
							value={pagination.pageIndex + 1}
							onChange={(e) => {
								const page = e.target.value ? Number(e.target.value) - 1 : 0
								setPagination((prev) => ({ ...prev, pageIndex: page }))
							}}
							size="sm"
							height="26px"
							width="42px"
							mr={2}
							borderRadius={"3px"}
						/>
						<Text>
							{t(
								"DevicesTable.deviceTablePaginationIndexText",
								{
									_: "von"
								},
								{ count: table.getPageCount() }
							)}{" "}
							{table.getPageCount()}
						</Text>
					</Flex>
					<IconButton
						icon={<ChevronRightIcon />}
						onClick={() =>
							setPagination((prev) => ({
								...prev,
								pageIndex: prev.pageIndex + 1
							}))
						}
						isDisabled={!table.getCanNextPage()}
						ml={2}
						size="sm"
						height="26px"
						aria-label={"nextPage"}
						borderRadius={"3px"}
					/>
					<IconButton
						icon={<ArrowRightIcon fontSize={"12px"} />}
						onClick={() =>
							setPagination((prev) => ({
								...prev,
								pageIndex: table.getPageCount() - 1
							}))
						}
						isDisabled={!table.getCanNextPage()}
						ml={2}
						size="sm"
						height="26px"
						aria-label={"lastPage"}
						borderRadius={"3px"}
					/>
				</Flex>
			</Flex>
		</Box>
	)
}
