import React, { useState, useEffect } from "react"
import { useNavigate, useSearchParams, Link as ReactRouterLink } from "react-router-dom"
import {
  Card,
  CardHeader,
  CardBody,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Alert,
  FormErrorMessage,
  VStack,
  Text,
  Link as ChakraLink,
  Box
} from "@chakra-ui/react"
import { 
  getAuth, 
  verifyPasswordResetCode, 
  confirmPasswordReset, 
  sendPasswordResetEmail,
  checkActionCode 
} from "firebase/auth"
import { useTranslate } from "react-polyglot"

const ResetPasswordHandler = () => {
  const [searchParams] = useSearchParams()
  const [userEmail, setUserEmail] = useState(null)
  const [isLinkExpired, setIsLinkExpired] = useState(false)
  const [resendSuccess, setResendSuccess] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const navigate = useNavigate()
  const auth = getAuth()
  const t = useTranslate()

  const actionCode = searchParams.get("oobCode")

  useEffect(() => {
    if (!actionCode) {
      setError(t("ResetPasswordHandler.invalidLink", {
        _: "Invalid reset link"
      }))
      setIsLinkExpired(true)
      setLoading(false)
      return
    }

    // First try to get the email associated with the action code
    checkActionCode(auth, actionCode)
      .then((actionCodeInfo) => {
        const email = actionCodeInfo.data.email
        // console.log("Retrieved email from action code:", email)
        setUserEmail(email)
        
        // Now verify if we can use this code for password reset
        return verifyPasswordResetCode(auth, actionCode)
      })
      .then(() => {
        setIsLinkExpired(false)
        setLoading(false)
      })
      .catch((error) => {
        console.error("Action code verification failed:", error)
        
        // Even if verification fails, we might have the email from the first step
        if (userEmail) {
          setIsLinkExpired(true)
          setError(t("ResetPasswordHandler.linkExpiredButCanResend", {
            _: "Der Link ist abgelaufen, fragen Sie einen neuen an"
          }))
        } else {
          setIsLinkExpired(true)
          setError(t("ResetPasswordHandler.linkExpiredPleaseRequestNew", {
            _: "Der Link ist abgelaufen"
          }))
          // Redirect to forgot password page after a delay
        //   setTimeout(() => {
        //     navigate('/forgot-password')
        //   }, 5000)
        }
        setLoading(false)
      })
  }, [actionCode, auth, navigate, t, userEmail])

  const validatePassword = (password) => {
    if (password.length < 6) {
      return t("ResetPasswordHandler.minimum6Characters", {
        _: "Mindestens 6 Zeichen"
      })
    }
    return ""
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    
    const passwordValidationError = validatePassword(newPassword)
    if (passwordValidationError) {
      setPasswordError(passwordValidationError)
      return
    }

    try {
      setLoading(true)
      await confirmPasswordReset(auth, actionCode, newPassword)
      navigate("/login", { 
        state: { 
          message: t("ResetPasswordHandler.passwordResetSuccess", {
            _: "Password has been reset successfully. Please login with your new password."
          })
        } 
      })
    } catch (error) {
      console.error("Password reset failed:", error)
      setError(t("ResetPasswordHandler.resetFailed", {
        _: "Failed to reset password. Please try again."
      }))
      setLoading(false)
    }
  }

  const handleResendLink = async () => {
    if (!userEmail) {
      navigate('/forgot-password')
      return
    }

    try {
      setLoading(true)
      await sendPasswordResetEmail(auth, userEmail, {
        url: window.location.origin + '/reset-password',
        handleCodeInApp: true
      })
      setResendSuccess(true)
      setError("")
      // Show success message and redirect after a delay
      setTimeout(() => {
        navigate('/login', {
          state: { 
            message: t("ResetPasswordHandler.newLinkSentCheckEmail", {
              _: "A new reset link has been sent to your email"
            })
          }
        })
      }, 3000)
    } catch (error) {
      console.error("Failed to send new reset link:", error)
      if (error.code === 'auth/too-many-requests') {
        setError(t("ResetPasswordHandler.tooManyRequests", {
          _: "Too many requests. Please try again later"
        }))
      } else {
        setError(t("ResetPasswordHandler.resendFailed", {
          _: "Failed to send new reset link. Please try again"
        }))
      }
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minH="200px">
        {t("ResetPasswordHandler.loading", { _: "Loading..." })}
      </Box>
    )
  }

  return (
    <Card maxW="400px" mx="auto" mt={8}>
      <CardHeader>
        <Heading size="lg">
          {isLinkExpired 
            ? t("ResetPasswordHandler.linkExpired", { _: "Link Expired" })
            : t("ResetPasswordHandler.resetPassword", { _: "Reset Password" })}
        </Heading>
      </CardHeader>
      <CardBody>
        {error && (
          <Alert 
            status={isLinkExpired ? "warning" : "error"}
            mb={4}
          >
            {error}
          </Alert>
        )}

        {isLinkExpired ? (
          <VStack spacing={4}>
            {userEmail && (
              <>
                <Text>
                  {t("ResetPasswordHandler.weCanSendNewLink", {
                    _: "Wir können einen neuen Link senden an: ",
                    email: userEmail
                  })}
                </Text>
                <Button
                  onClick={handleResendLink}
                  isLoading={loading}
                  loadingText={t("ResetPasswordHandler.sending", { _: "Sending..." })}
                  width="100%"
                  size={"lg"}
                  height={"42px"}
                >
                  {t("ResetPasswordHandler.sendNewLink", { _: "Send New Link" })}
                </Button>
              </>
            )}
            <ChakraLink
              mt={4}
              as={ReactRouterLink}
              to="/forgot-password"
              color="blue.500"
            >
              {t("ResetPasswordHandler.requestNewResetLink", {
                _: "Request New Password Reset"
              })}
            </ChakraLink>
          </VStack>
        ) : (
          <form onSubmit={handleSubmit}>
            <VStack spacing={4}>
              <FormControl isInvalid={passwordError}>
                <FormLabel>
                  {t("ResetPasswordHandler.newPassword", { _: "New Password" })}
                </FormLabel>
                <Input
                  type="password"
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value)
                    setPasswordError(validatePassword(e.target.value))
                  }}
                  placeholder={t("ResetPasswordHandler.enterNewPassword", {
                    _: "Neues Passwort eingeben (mind. 6 Zeichen)"
                  })}
                  _placeholder={{
                    color: "field-placeholder-text"
                  }}
                />
                <FormErrorMessage>{passwordError}</FormErrorMessage>
              </FormControl>
              
              <Button 
                type="submit" 
                width="100%"
                isLoading={loading}
                loadingText={t("ResetPasswordHandler.resetting", {
                  _: "Resetting..."
                })}
                size={"lg"}
                height={"42px"}
                mb={6}
              >
                {t("ResetPasswordHandler.resetPassword", { _: "Reset Password" })}
              </Button>
            </VStack>
          </form>
        )}

        {resendSuccess && (
          <Alert status="success" mt={4}>
            {t("ResetPasswordHandler.newLinkSentCheckEmail", {
              _: "Ein neuer Link wurde an Ihre E-Mail gesendet"
            })}
          </Alert>
        )}
      </CardBody>
    </Card>
  )
}

export default ResetPasswordHandler